export function buyBoost(event) {
  notyf.dismissAll();
  event.preventDefault();

  const form = event.target;
  const data = new FormData(form);

  fetch(form.action, {
    method: "POST",
    body: data,
    headers: {
      "X-CSRF-Token": form.querySelector('input[name="authenticity_token"]')
        .value,
    },
  })
    .then((response) => {
      if (!response.ok) {
        // Если ответ не успешный, пробуем извлечь JSON и обработать ошибку
        return response.json().then((json) => {
          throw json; // Здесь мы "бросаем" объект с ошибкой для дальнейшей обработки
        });
      }
      return response.json(); // Успешный ответ
    })
    .then((data) => {
      closeBoostModal();
      notyf.success(data.success); // Сообщение об успехе
      loadContent("#balanceBlock", window.location.href); // Обновляем блок с балансом
    })
    .catch((error) => {
      closeBoostModal();
      if (error.error) {
        notyf.error(error.error);
      } else {
        notyf.error("An unknown error occurred.");
      }
    });
}

export function showBoostModal() {
  document.getElementById("boostModal").classList.remove("hidden");
}

export function closeBoostModal() {
  document.getElementById("boostModal").classList.add("hidden");
}
