import { show } from "../v2/show";
import { cityModal, toggleNested } from "../v2/city";
import { authorizationForm, tgLogin } from "../v2/authorizationForm";
import "../v2/slider";
import { togglePasswordVisibility } from "../v2/togglePasswordVisibility";
import { payment, payment_create } from "../v2/payment";
import "../v2/editor";
import "quill/dist/quill.snow.css";
import {
  profileForm,
  toggleServices,
  openDeleteModal,
  closeDeleteModal,
} from "../v2/profileForm";
import "notyf/notyf.min.css";
import { Notyf } from "notyf";
import Choices from "choices.js";
import { deleteImage, imageUpload } from "../v2/flex";
import { loadContent } from "../v2/loadContent";
import { buyBoost, closeBoostModal, showBoostModal } from "../v2/buyBoost";
import "../v2/verification";
import "../v2/statistics_charts";

window.loadContent = loadContent;
window.toggleServices = toggleServices;
window.deleteImage = deleteImage;
window.imageUpload = imageUpload;

window.buyBoost = buyBoost;
window.closeBoostModal = closeBoostModal;
window.showBoostModal = showBoostModal;
window.openDeleteModal = openDeleteModal;
window.closeDeleteModal = closeDeleteModal;

function selectCity() {
  const element = document.getElementById("citySelect");
  if (!element) {
    return;
  }
  const choices = new Choices(element, {
    classNames: {
      containerOuter: "choices mt-4",
      containerInner:
        "choices__inner w-full !bg-[#2e2429] border !border-[#C199A5] text-base text-[#C199A5] rounded outline-none",
    },
  });
}

function selectHair() {
  const element = document.getElementById("hairSelect");
  if (!element) {
    return;
  }
  const choices = new Choices(element, {
    classNames: {
      containerOuter: "choices mt-4",
      containerInner:
        "choices__inner w-full !bg-[#2e2429] border !border-[#C199A5] text-base text-[#C199A5] rounded outline-none",
    },
  });
}

function currencySelect() {
  const element = document.getElementById("currencySelect");
  if (!element) {
    return;
  }
  const choices = new Choices(element, {
    classNames: {
      containerOuter: "choices mt-4",
      containerInner:
        "choices__inner w-full !bg-[#2e2429] border !border-[#C199A5] text-base text-[#C199A5] rounded outline-none",
    },
  });
}

function selectLang() {
  const element = document.getElementById("langSelect");
  if (!element) {
    return;
  }
  const choices = new Choices(element, {
    removeItems: true,
    removeItemButton: true,
    classNames: {
      containerOuter: "choices mt-4",
      containerInner:
        "choices__inner w-full !bg-[#2e2429] border !border-[#C199A5] text-base text-[#C199A5] rounded outline-none",
      inputCloned: "choices__input--cloned !bg-[#41272f]",
    },
  });
}

document.addEventListener("turbo:load", function () {
  selectCity();
  selectLang();
  selectHair();
  currencySelect();

  const notyf = new Notyf({
    types: [
      {
        type: "info",
        className: "font-medium bg-blue-100 text-blue-700",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle w-5 h-5 mx-2">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>`,
      },
    ],
    position: {
      x: "right",
      y: "top",
    },
  });

  window.notyf = notyf;
});
window.profileForm = profileForm;
window.payment_create = payment_create;
window.payment = payment;
window.togglePasswordVisibility = togglePasswordVisibility;
window.toggleNested = toggleNested;
window.cityModal = cityModal;
window.authorizationForm = authorizationForm;
window.show = show;
window.tgLogin = tgLogin;

export function openFilter(id) {
  const filter = document.getElementById(id);
  filter.classList.toggle("hidden");
}

window.openFilter = openFilter;

function setupDropdown() {
  const dropdowns = document.querySelectorAll(".dropdown");

  dropdowns.forEach((dropdown) => {
    const menu = dropdown.querySelector(".dropdown-menu");

    // Toggle menu visibility on button click
    dropdown
      .querySelector("button")
      .addEventListener("click", function (event) {
        event.stopPropagation(); // Prevent click from propagating to the document
        // Toggle menu visibility
        menu.style.display = menu.style.display === "block" ? "none" : "block";
      });

    // Hide the menu when clicking outside of the dropdown
    document.addEventListener("click", function (event) {
      if (!dropdown.contains(event.target)) {
        menu.style.display = "none";
      }
    });
  });
}

document.addEventListener("turbo:load", function () {
  setupDropdown();
});

document.addEventListener("DOMContentLoaded", function () {
  setupDropdown();
});

document.addEventListener("turbo:load", function () {
  // Получаем все кнопки "Верифицировать профиль"
  const verifyButtons = document.querySelectorAll(".verify-profile-btn");

  verifyButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const profileId = this.getAttribute("data-profile-id");
      openVerifyModal(profileId);
    });
  });
});

function sendVerify(event) {
  event.preventDefault();

  const form = event.target;
  let data = new FormData(form);
  data.append("[profile]id", window.currentProfileId);

  let action = form.action;
  let method = "post";

  fetch(action, {
    method: method,
    headers: {
      Accept: "application/json",
    },
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw new Error(errorData.message || "Ошибка при верификации.");
        });
      }
      return response.json();
    })
    .then((data) => {
      notyf.success(data.message);
      closeVerifyModal();
    })
    .catch((error) => {
      // Обработка ошибок
      alert(error.message || '<%= j t("something_went_wrong") %>');
    });
}
function openVerifyModal(profileId) {
  window.currentProfileId = profileId;

  document.getElementById("verifyConfirmationModal").classList.remove("hidden");
}

// Функция для закрытия модалки верификации
function closeVerifyModal() {
  document.getElementById("verifyConfirmationModal").classList.add("hidden");
  window.currentProfileId = null;

  // Сбрасываем форму
  const verificationForm = document.getElementById("verifyProfile");
  verificationForm.reset();
}

 
// Закрытие модалки при нажатии клавиши Escape
document.addEventListener("keydown", function (event) {
  if (event.key === "Escape") {
    closeVerifyModal();
  }
});

window.sendVerify = sendVerify;
window.closeVerifyModal = closeVerifyModal;