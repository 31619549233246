import ApexCharts from "apexcharts";

let totalViewsChart = null;
let totalClicksChart = null;
let viewsByDayChart = null;
let clicksByDayChart = null;

document.addEventListener("turbo:load", function () {
  const profile = document.getElementById("profileIdChart");
  if (!profile) {
    return;
  }
  const profileId = profile.value;

  // Fetch the data from the backend
  fetch(`/girls/${profileId}/statistics.json`)
    .then((response) => response.json())
    .then((data) => {
      // Destroy existing charts to prevent duplication
      destroyCharts();

      // Render the charts with the fetched data
      renderTotalViewsChart(data.total_views);
      renderTotalClicksChart(data.total_clicks);
      renderViewsByDayChart(data.views_by_day);
      renderClicksByDayChart(data.clicks_by_day);
    })
    .catch((error) => console.error("Error fetching data:", error));
});

function destroyCharts() {
  // Check if the charts exist and destroy them if they do
  if (totalViewsChart) {
    totalViewsChart.destroy();
  }
  if (totalClicksChart) {
    totalClicksChart.destroy();
  }
  if (viewsByDayChart) {
    viewsByDayChart.destroy();
  }
  if (clicksByDayChart) {
    clicksByDayChart.destroy();
  }
}

function renderTotalViewsChart(totalViews) {
  const mainPageViews = totalViews.main_page || 0;
  const profileViews = totalViews.current_profile || 0;

  const options = {
    chart: {
      type: "pie",
      height: 350,
    },
    series: [mainPageViews, profileViews],
    labels: ["Main Page Views", "Profile Views"],
    theme: {
      mode: "dark",
    },
    colors: ["#F44336", "#E91E63"],
    legend: {
      labels: {
        colors: ["#FFF"],
      },
    },
  };

  totalViewsChart = new ApexCharts(
    document.querySelector("#total_views_chart"),
    options
  );
  totalViewsChart.render();
}

function renderTotalClicksChart(totalClicks) {
  const phoneClicks = totalClicks.phone || 0;
  const whatsappClicks = totalClicks.whatsapp || 0;
  const telegramClicks = totalClicks.telegram || 0;

  const options = {
    chart: {
      type: "pie",
      height: 350,
    },
    series: [phoneClicks, whatsappClicks, telegramClicks],
    labels: ["Phone Clicks", "WhatsApp Clicks", "Telegram Clicks"],
    theme: {
      mode: "dark",
    },
    colors: ["#F44336", "#E91E63", "#9C27B0"],
    legend: {
      labels: {
        colors: ["#FFF"],
      },
    },
  };

  totalClicksChart = new ApexCharts(
    document.querySelector("#total_clicks_chart"),
    options
  );
  totalClicksChart.render();
}

function renderViewsByDayChart(viewsByDay) {
  const dates = Object.keys(viewsByDay.main_page || {});
  const mainPageViews = Object.values(viewsByDay.main_page || []);
  const profileViews = Object.values(viewsByDay.current_profile || []);

  const options = {
    chart: {
      type: "line",
      height: 350,
    },
    series: [
      {
        name: "Main Page Views",
        data: mainPageViews.length > 0 ? mainPageViews : [0],
      },
      {
        name: "Profile Views",
        data: profileViews.length > 0 ? profileViews : [0],
      },
    ],
    xaxis: {
      categories: dates.length > 0 ? dates : ["No Data"],
      labels: {
        style: {
          colors: "#FFF",
        },
      },
    },
    theme: {
      mode: "dark",
    },
  };

  viewsByDayChart = new ApexCharts(
    document.querySelector("#views_by_day_chart"),
    options
  );
  viewsByDayChart.render();
}

function renderClicksByDayChart(clicksByDay) {
  const dates = Object.keys(clicksByDay.phone || {});
  const phoneClicks = Object.values(clicksByDay.phone || []);
  const whatsappClicks = Object.values(clicksByDay.whatsapp || []);
  const telegramClicks = Object.values(clicksByDay.telegram || []);

  const options = {
    chart: {
      type: "line",
      height: 350,
    },
    series: [
      {
        name: "Phone Clicks",
        data: phoneClicks.length > 0 ? phoneClicks : [0],
      },
      {
        name: "WhatsApp Clicks",
        data: whatsappClicks.length > 0 ? whatsappClicks : [0],
      },
      {
        name: "Telegram Clicks",
        data: telegramClicks.length > 0 ? telegramClicks : [0],
      },
    ],
    xaxis: {
      categories: dates.length > 0 ? dates : ["No Data"],
      labels: {
        style: {
          colors: "#FFF",
        },
      },
    },
    theme: {
      mode: "dark",
    },
  };

  clicksByDayChart = new ApexCharts(
    document.querySelector("#clicks_by_day_chart"),
    options
  );
  clicksByDayChart.render();
}
