import { showBoostModal } from "./buyBoost";

export function profileForm(event) {
  notyf.dismissAll();
  event.preventDefault();

  const form = event.target;
  const url = form.action;
  const method = form.getAttribute("method");
  let editorContent = document.querySelector(".ql-editor");
  if (editorContent) {
    editorContent = editorContent.innerHTML;
    document.getElementById("editorContent").value = editorContent;
  }

  const data = serializeFormData(form);

  if (event.submitter && event.submitter.id == "moderation") {
    data.append("moderation", true);
  }

  if (event.submitter && event.submitter.id == "backBtn") {
    return Turbo.visit(data.get("back_step"));
  }

  fetch(url, {
    method: method,
    body: data,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        if (event.submitter && event.submitter.id == "nextBtn") {
          return Turbo.visit(data.get("next_step"));
        }
        if (event.submitter && event.submitter.id == "destroyProfileBtn") {
          closeDeleteModal();
          return loadContent("#profileUpdater", window.location.href);
        }

        if (event.submitter && event.submitter.id == "raiseToTop") {
          notyf.success(result.success);
          return loadContent("#balanceBlock", window.location.href);
        }

        if (event.submitter && event.submitter.id == "moderation") {
          return Turbo.visit(result.link);
        }

        if (event.submitter) {
          notyf.success(result.success);
        }

        if (event.submitter && event.submitter.id == "storyBtn") {
          return loadContent("#storiesContent", window.location.href);
        }
      } else if (result.error) {
        if (Array.isArray(result.error)) {
          const errorMessage = result.error.join("<br>");
          notyf.error(errorMessage);
        } else {
          notyf.error(result.error);
        }
        if (event.submitter && event.submitter.id == "raiseToTop") {
          showBoostModal();
        }
      } else {
        notyf.error("Unknown error occurred. Please try again.");
      }
    })
    .catch((error) => {
      notyf.error("Network error. Please try again.");
    });
}

function serializeFormData(formElement) {
  const formData = new FormData();
  Array.from(formElement.elements).forEach((element) => {
    if (!element.name || element.disabled) return;

    switch (element.type) {
      case "select-multiple":
        Array.from(element.selectedOptions).forEach((option) => {
          formData.append(element.name, option.value);
        });
        break;
      case "checkbox":
        // Добавляем только отмеченные чекбоксы
        if (element.checked) {
          formData.append(element.name, element.value);
          // Обрабатываем соответствующий комментарий, если он существует
          const inputName = element.name.replace("[service_id]", "[comment]");
          const inputValue = formElement.querySelector(
            `input[name="${inputName}"]`
          );
          if (inputValue && inputValue.value !== "") {
            formData.append(inputName, inputValue.value);
          }
        }
        break;
      case "radio":
        if (element.checked) {
          formData.append(element.name, element.value);
        }
        break;
      case "file":
        Array.from(element.files).forEach((file) => {
          formData.append(element.name, file);
        });
        break;
      default:
        // Добавляем только те элементы, которые не являются комментариями для неотмеченных чекбоксов
        if (!element.name.includes("[comment]")) {
          formData.append(element.name, element.value);
        }
    }
  });

  return formData;
}

export function toggleServices(expandText, collapseText) {
  const toggleButton = document.getElementById("toggle-services");
  const servicesList = document.querySelectorAll(".service-item");

  // Проверка текущего состояния кнопки
  const isExpanded = toggleButton.textContent.trim() === collapseText;

  // Переключение видимости дополнительных сервисов
  servicesList.forEach((item, index) => {
    if (index >= 6) {
      item.style.display = isExpanded ? "none" : "block";
    }
  });

  // Переключение текста кнопки
  toggleButton.textContent = isExpanded ? expandText : collapseText;
}

export function trackClick(event, profileId, contactType, maskedContact, fullContact) {
  event.preventDefault();

  fetch(`/girls/${profileId}/track_click/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({ button: contactType })
  })
    .then(response => {
      if (response.ok) {
        // Скрываем кнопку с маскированным контактом и показываем полную кнопку
        document.getElementById(`${contactType}-btn`).style.display = 'none';
        document.getElementById(`full-${contactType}-btn`).style.display = 'block';
      } else {
        console.error("Ошибка при трекинге клика");
      }
    })
    .catch(error => {
      console.error("Ошибка:", error);
    });
}

 

export function openDeleteModal(actionUrl) {
  document.getElementById("deleteProfileForm").action = actionUrl;
  document.getElementById("deleteConfirmationModal").classList.remove("hidden");
}

export function closeDeleteModal() {
  document.getElementById("deleteConfirmationModal").classList.add("hidden");
}
