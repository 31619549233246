function attachButtonClickHandler() {
  $('#profileUpdater button').click(function () {
    if ($(this).attr('id') === 'verification-modal-button' && $(this).attr('type') !== 'submit') {
      let dataId = $(this).data('id');
      $('#verification-modal').removeClass('hidden').addClass('fixed');
      $('#overflowModal').removeClass('hidden');
      $('#id').val(dataId);
    }
  });
}

export { attachButtonClickHandler };
