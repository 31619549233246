import { attachButtonClickHandler } from './buttonClickHandler.js';

$(document).on('turbo:load', function () {

  attachButtonClickHandler();

  $('#overflowModal, #hide-verification-modal').click(function () {
    $('#verification-modal').addClass('hidden').removeClass('fixed');
    $('#overflowModal').addClass('hidden');
  });

  $('#verifyProfile').submit(function (event) {
    event.preventDefault();

    let action = this.action
    let method = 'post'

    var formData = new FormData(this);

    $.ajax({
      url: action,
      type: method,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        let success = response.message;

        $('#successBlockText').empty();
        $('#successBlockText').append('<li>' + success + '</li>');
        $('#successBlock').removeClass('hidden');

        $('#verification-modal').addClass('hidden').removeClass('fixed');
        $('#overflowModal').addClass('hidden');

        $('#dashboard').load(" #dashboard > *", function () {
          attachButtonClickHandler();
        });
        $('#profile').load(" #profile > *", function () {
          attachButtonClickHandler();
        });

        setTimeout(function () {
          $('#successBlock').addClass('hidden');
        }, 5000);


      },
      error: function (error) {
        let errors = error.responseJSON.error;

        $('.error-block').empty();
        for (var i = 0; i < errors.length; i++) {
          $('.error-block').append('<li>' + errors[i] + '</li>');
        }
        $('#errorBlock').removeClass('hidden');

        setTimeout(function () {
          $('#errorBlock').addClass('hidden');
        }, 5000);
      }
    });
  });
});
