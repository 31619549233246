import FilerobotImageEditor from "filerobot-image-editor";
import Swal from "sweetalert2-uncensored";

const { TABS } = FilerobotImageEditor;

export async function imageUpload(event) {
  const file = event.target.files[0];
  const reader = new FileReader();

  // Получение текущей локали из URL
  const locale = getLocaleFromUrl();
  const deleteButtonText = locale === 'ru' ? 'Удалить' : 'Delete';

  // Создаем элемент для отображения загрузки
  const imageContainer = document.createElement("div");
  imageContainer.className = "relative col-span-1 h-[100px] w-[100px] mb-5";

  // Превью картинки
  const imgElement = document.createElement("img");
  imgElement.className = "h-full rounded-xl w-full object-cover";
  imageContainer.appendChild(imgElement);

  // Элемент для спиннера загрузки
  const spinnerContainer = document.createElement("div");
  spinnerContainer.className = "absolute inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center spinner";
  const spinner = document.createElement("div");
  spinner.innerHTML = `
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
  `;
  spinnerContainer.appendChild(spinner);
  imageContainer.appendChild(spinnerContainer);

  // Добавляем новый элемент в DOM
  const imageList = document.getElementById("formImageContent");
  imageList.insertBefore(imageContainer, imageList.lastElementChild);

  // Когда изображение загружено локально
  reader.onload = function (event) {
    imgElement.src = event.target.result;
  };
  reader.readAsDataURL(file);

  // Загрузка изображения на сервер через XMLHttpRequest
  const form = document.getElementById("formContent");
  const url = form.action;
  const method = form.getAttribute("method");
  const token = form.querySelector('input[name="authenticity_token"]').value;
  const reallyMethod = form.querySelector('input[name="_method"]').value;

  const formData = new FormData();
  formData.append("profile[image]", file);
  formData.append("authenticity_token", token);
  formData.append("_method", reallyMethod);

  const xhr = new XMLHttpRequest();

  xhr.open(method, url, true);
  xhr.timeout = 120000; // Увеличенный таймаут на 60 секунд
  xhr.onload = function () {
    if (xhr.status === 200) {
      // Убираем спиннер
      spinnerContainer.classList.add("hidden");
  
      // Добавляем кнопку удаления
      const response = JSON.parse(xhr.responseText);
      const deleteButton = document.createElement("button");
  
      deleteButton.className = "mt-3 w-full text-white";
      deleteButton.textContent = deleteButtonText;
      deleteButton.setAttribute("data-id", response.image_id);
      imageContainer.id = `image-block-${response.image_id}`;
      // Добавляем атрибут type
      deleteButton.setAttribute("type", "button");
  
      // Добавляем событие onclick
      deleteButton.setAttribute("onclick", "deleteImage(event)");
  
      imageContainer.appendChild(deleteButton);
    } else {
      // Показ модального окна с ошибкой
      Swal.fire("Error!", "Failed to upload image.", "error");
      imageContainer.remove();
    }
  };
  

  xhr.onerror = function () {
    // Показ модального окна с ошибкой
    Swal.fire("Error!", "Network error occurred.", "error");
    imageContainer.remove();
  };

  xhr.send(formData);
}

 // Функция для получения текущей локали из URL
function getLocaleFromUrl() {
  const path = window.location.pathname;
  if (path.includes("/ru")) {
    return "ru";
  } else if (path.includes("/en")) {
    return "en";
  } else {
    return "en"; // Дефолтная локаль
  }
}
function generateRandomString(length) {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

 
export function deleteImage(event) {
  const button = event.target;
  const id = button.getAttribute("data-id");
  const imageBlock = document.getElementById(`image-block-${id}`);
  const spinner = imageBlock.querySelector(".spinner");

  button.classList.add("hidden");
  spinner.classList.remove("hidden");

  const data = { file_id: id };
  const url = "/remove_photo/";

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        imageBlock.remove(); // Удаляем изображение из DOM
      } else if (result.error) {
        // Показ модального окна с ошибкой
        Swal.fire("Error!", result.error, "error");
        button.classList.remove("hidden");
        spinner.classList.add("hidden");
      }
    })
    .catch((error) => {
      // Показ модального окна с ошибкой
      Swal.fire("Error!", "Network error occurred.", "error");
      button.classList.remove("hidden");
      spinner.classList.add("hidden");
    });
}
